<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Заказ</div>
                <div class="page__desc">Информация о заказе</div>
            </div>
        </div>

        <div v-if="order" class="page__content">
            <h2>
                <b-button variant="secondary">№{{ order.id }}</b-button>
                <b-button variant="secondary">СТАТУС: {{ order.status.id }}</b-button>
                <b-button variant="secondary">ДОСТАВКА: {{ order.slot.time_from | moment("DD MMM YYYY HH:mm") }} - {{ order.slot.time_to | moment("HH:mm") }}</b-button>

                <b-button v-if="order.status.id === 'CREATED'" variant="outline-dark" @click="changeStatus('ASSEMBLING')">CHANGE TO ASSEMBLING</b-button>
                <b-button v-if="order.status.id === 'ASSEMBLING'" variant="outline-primary" @click="changeStatus('ASSEMBLED')">CHANGE TO ASSEMBLED</b-button>
                <b-button v-if="order.status.id === 'ASSEMBLED'" variant="outline-warning" @click="changeStatus('DRIVING')">CHANGE TO DRIVING</b-button>
                <b-button v-if="order.status.id === 'DRIVING'" variant="outline-success" @click="changeStatus('COMPLETED')">CHANGE TO COMPLETED</b-button>
                <b-button v-if="order.status.id !== 'CANCELLED' && order.status.id !== 'COMPLETED'" variant="outline-danger" @click="changeStatus('CANCELLED')">CHANGE TO CANCELLED</b-button>
            </h2>

            <br>

            <div class="row">
                <div class="col-sm-6">
                    <b-badge variant="primary">Адрес</b-badge>
                    <br>
                    <form v-if="order.address != null" class="form-group" method="POST" action="" @submit.prevent="updateAddress()">
                        <b>Страна: </b> {{ order.address.country }}<br>
                        <b>Град: </b> {{ order.address.city }}<br>
                        <b>Улица: </b> {{ order.address.street }}<br>
                        <br>

                        <div class="row">
                            <div class="col-sm-3">
                                <label for="house">Блок</label>
                                <div class="control-group">
                                    <input
                                            v-model="order.address.house"
                                            id="house"
                                            class="form-control"
                                            required
                                            readonly>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label for="floor">Вход</label>
                                <div class="control-group">
                                    <input
                                            v-model="order.address.floor"
                                            id="floor"
                                            class="form-control"
                                            required>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label for="entrance">Етаж</label>
                                <div class="control-group">
                                    <input
                                            v-model="order.address.entrance"
                                            id="entrance"
                                            class="form-control"
                                            required>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label for="apartment">Ап.</label>
                                <div class="control-group">
                                    <input
                                            v-model="order.address.apartment"
                                            id="apartment"
                                            class="form-control"
                                            required>
                                </div>
                            </div>
                        </div>
                        <br>

                        <label for="comment">Комментарий</label>
                        <div class="control-group">
                            <input
                                    v-model="order.address.comment"
                                    id="comment"
                                    type="text"
                                    class="form-control"
                                    required>
                        </div>
                        <br>

                        <label for="lat">Координаты</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="control-group">
                                    <input
                                            v-model="order.address.lat"
                                            id="lat"
                                            class="form-control"
                                            required>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="control-group">
                                    <input
                                            v-model="order.address.lng"
                                            id="lng"
                                            class="form-control"
                                            required>
                                </div>
                            </div>
                        </div>
                        <b><a :href="'http://maps.google.com/maps?q=loc:' + order.address.lat + ',' + order.address.lng" target="_blank">Виж на Google Maps</a></b>
                        <br><br>

                        <input type="submit" class="btn btn-success btn-large" value="Обновить">
                    </form>
                </div>
                <div class="col-sm-6">
                    <b-badge variant="primary">Потребител</b-badge>
                    <br>
                    <template v-if="order.user != null">
                        <b>ID: </b> {{ order.user.id }}<br>
                        <b>Име: </b> {{ order.user.name }}<br>
                        <b>Телефон: </b> {{ order.user.phone }}<br>
                        <b>Email: </b> {{ order.user.email }}<br>
                    </template>
                    <template v-else>
                        <b>DEVICE ID: </b> {{ order.device_id }}<br>
                    </template>

                    <br>
                    <b-badge variant="primary">Метод на плащане</b-badge>
                    <br>
                    <b-dropdown :text="order.payment.title" variant="outline-secondary">
                        <b-dropdown-item variant="secondary" @click="changePaymentMethod(-1)">На място в брой</b-dropdown-item>
                        <b-dropdown-item variant="secondary" @click="changePaymentMethod(-2)">На място с карта</b-dropdown-item>
                    </b-dropdown>&nbsp;
                    <template v-if="order.pay_status === 'COMPLETED'">Платена</template>
                    <template v-else-if="order.pay_status === 'RESERVED'">Сумата е блокирана</template>
                    <template v-else>Не платена</template>

                    <br>
                    <br>
                    <b-badge variant="primary">История статусов заказа</b-badge>
                    <br>
                    <table class="table table-bordered table-sm">
                        <tr v-for="status in statusesHistory">
                            <td>{{ status.status }}</td>
                            <td>{{ status.created_at | moment("DD MMM YYYY HH:mm") }}</td>
                            <td>
                                <template v-if="status.admin_id !== null">By admin (#{{ status.admin_id }})</template>
                                <template v-else>By user</template>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <br>

            <form class="form-group" method="POST" action="" @submit.prevent="updateProducts()">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th style="width: 150px;"></th>
                            <th style="width: 300px;">Наименование</th>
                            <th style="width: 150px;">Кол-во</th>
                            <th>Стоимость</th>
                            <th>Итого</th>
                            <th>Изменения</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="product in order.products"
                            v-bind:key="product.line_id">

                            <template>
                                <td><img :src="product.photo.url" style="width: 100px;"></td>
                                <td>
                                    <router-link
                                            :to="{ name: 'CatalogProductUpdate', params: {id: product.id } }" >{{ product.title }}</router-link>
                                    &nbsp;<small>{{ product.weight.text }}</small>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input
                                                v-model="product.quantity"
                                                id="quantity"
                                                type="number"
                                                class="form-control"
                                                required>
                                        <div class="input-group-append">
                                            <span class="input-group-text">{{ product.packaging.value }}</span>
                                        </div>
                                    </div>
                                    <span class="badge badge-light">Има още: {{ product.stock.quantity }} {{ product.packaging.value }}</span>
                                </td>
                                <td>
                                    <template v-if="product.price.special !== product.price.normal"><span class="badge badge-primary">{{ product.price.special }} ЛВ</span> <s class="badge badge-light">{{ product.price.normal }} ЛВ</s></template>
                                    <template v-else><span class="badge badge-light">{{ product.price.normal }} ЛВ</span></template>
                                </td>
                                <td>
                                    <template v-if="product.price_sum.special !== product.price_sum.normal"><span class="badge badge-primary">{{ product.price_sum.special }} ЛВ</span> <s class="badge badge-light">{{ product.price_sum.normal }} ЛВ</s></template>
                                    <template v-else><span class="badge badge-light">{{ product.price_sum.normal }} ЛВ</span></template>
                                </td>
                                <td>
                                    <template v-if="productsHistory[product.id]"
                                             v-for="productHistory in productsHistory[product.id]">

                                        <b-badge
                                                :variant="productHistory.admin_id == null ? 'light' : 'dark'">

                                            {{ productHistory.quantity }} ({{ productHistory.created_at | moment("DD MMM YYYY HH:mm") }} <span v-if="productHistory.admin_id == null">by user</span><span v-else>by admin</span>)
                                        </b-badge>
                                    </template>
                                </td>
                            </template>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="5">
                                <router-link
                                        v-if="order.status.id === 'CREATED' || order.status.id === 'ASSEMBLING'"
                                        :to="{ name: 'DeliveryOrdersAddProduct', params: {id: order.id } }"
                                        class="btn btn-warning btn-large">Добавить товар</router-link>
                                <input
                                        v-if="order.status.id === 'CREATED' || order.status.id === 'ASSEMBLING'"
                                        type="submit"
                                        class="btn btn-warning btn-large"
                                        value="Сохранить количество товаров">
                            </td>
                            <td>
                                <h3>
                                    <template v-if="order.delivery != null">
                                        <span class="badge badge-light">Доставка: {{ order.delivery.price.special }} ЛВ <s v-if="order.delivery.price.normal !== order.delivery.price.special"><small>{{ order.delivery.price.normal }} ЛВ</small></s></span><br>
                                    </template>
                                    <template v-if="order.price.discount > 0">
                                        <span class="badge badge-light">Скидка: -{{ order.price.discount }} ЛВ</span><br>
                                    </template>
                                </h3>
                                <b-button size="sm" variant="primary">Итого: {{ order.price.special }} ЛВ <b-badge v-if="order.price.special !== order.price.normal"><s>{{ order.price.normal }} ЛВ</s></b-badge></b-button>
                                <br>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'DeliveryOrdersUpdate',
        computed: {
            order() {
                return this.$store.state.orders.order
            },
            statusesHistory() {
                return this.$store.state.orders.statusesHistory
            },
            productsHistory() {
                return this.$store.state.orders.productsHistory
            },
            orderErrorMessage() {
                return this.$store.state.orders.orderErrorMessage
            }
        },
        methods: {
            ...mapActions([
                'deliveryOrdersGet',
                'deliveryOrdersAddress',
                'deliveryOrdersProducts',
                'deliveryOrdersStatus',
                'deliveryOrdersStatusesHistory',
                'deliveryOrdersProductsHistory',
                'deliveryOrdersMessageClear',
                'deliveryOrdersPaymentMethod'
            ]),
            async getOrder() {
                await this.deliveryOrdersGet({
                    id: this.$route.params.id,
                })
            },
            async updateAddress() {
                await this.deliveryOrdersAddress({
                    id: this.order.address.id,
                    house: this.order.address.house,
                    floor: this.order.address.floor,
                    entrance: this.order.address.entrance,
                    apartment: this.order.address.apartment,
                    comment: this.order.address.comment,
                    lat: this.order.address.lat,
                    lng: this.order.address.lng
                })
            },
            async updateProducts() {
                let data = [];
                for (let i=0; i<this.order.products.length; i++) {
                    data.push({
                        id: this.order.products[i].id,
                        line_id: this.order.products[i].line_id,
                        quantity: this.order.products[i].quantity
                    });
                }

                await this.deliveryOrdersProducts({
                    id: this.order.id,
                    products: data
                })
            },
            async changeStatus(status) {
                await this.deliveryOrdersStatus({
                    id: this.order.id,
                    status: status
                })
            },
            async changePaymentMethod(payment_id) {
                await this.deliveryOrdersPaymentMethod({
                    id: this.order.id,
                    payment_id: payment_id
                })
            },
            async getStatusesHistory() {
                await this.deliveryOrdersStatusesHistory({
                    id: this.$route.params.id
                })
            },
            async getProductsHistory() {
                await this.deliveryOrdersProductsHistory({
                    id: this.$route.params.id
                })
            },
            async clearErrorMessage() {
                await this.deliveryOrdersMessageClear();
            }
        },
        watch: {
            $route() {
                this.getOrder();
                this.getStatusesHistory();
                this.getProductsHistory();
            },
            orderErrorMessage: function (newVal) {
                if (newVal != null) {
                    this.$bvToast.toast(newVal, {
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        solid: true,
                        noCloseButton: true
                    });
                }
                this.getOrder();
                this.clearErrorMessage();
            }
        },
        created() {
            this.getOrder();
            this.getStatusesHistory();
            this.getProductsHistory();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>